<template>
<div class="app-page-2">
  <div class="container">
    <Banner></Banner>
    <div class="mineContainer">
      <LeftNav></LeftNav>
      <Course v-if="type === 0" />
      <Orders v-if="type === 1" />
      <Protocol v-if="type === 2" />
      <Question v-if="type ===3" />
      <Info v-if="type === 4" />
    </div>
  </div>
</div>
</template>
<script>
import Banner from '@/components/mine/banner.vue'
import LeftNav from '@/components/mine/leftNav.vue'

import Info from '@/components/mine/info.vue'
import Course from '@/components/mine/course.vue'
import Orders from '@/components/mine/orders.vue'
import Protocol from '@/components/mine/protocol.vue'
import Question from '@/components/mine/question.vue'

export default {
  
  middleware: 'accountVerification',
  name:'course',
  components:{
    Banner,
    LeftNav,
    Info,
    Course,
    Orders,
    Protocol,
    Question
  },
  data() {
    return {
      type: 0
    }
  },
  methods: {
    // typeChange(val = 1) {
    //     this.type = val
    // }
  },
  mounted() {
    this.type = this.$route.query.type ? +this.$route.query.type : 0
  },
  watch: {
    '$route': {
      handler() {
        this.type = this.$route.query.type ? +this.$route.query.type : 0
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    }
  },
}
</script>
<style lang="less" scoped>
.container{
  padding-top:0px;
  overflow: hidden;
}
.mineContainer{
  width:1200px;
  overflow: hidden;
  background:#fff;
  margin:30px auto;
  border: 1px solid #DBDBDB;
  display: flex;
}
.infoContain{
  width:985px;
  padding:0 30px;
  overflow: hidden;
  background:#fff;
  border-left:1px solid #E8E8E8;
  min-height: 375px;
  float:left;
  .title h3{
    font-size: 22px;
    color:#333333;
    text-align: left;
    font-weight: normal;
    line-height: 75px;
    height: 75px;
  }
  .infoBox{
    .modifyPassword{
      float: right;
      width:115px;
      height:40px;
      line-height: 40px;
      border:1px solid #FF3F47;
      border-radius: 6px;
      color:#FF3F47;
      font-size: 16px;
      text-align: center;
    }
    .footer{
      width: 100%;
      height:100px;
      .updateBtn{
        width:115px;
        height:40px;
        line-height: 40px;
        background: #FF3F47;
        border-radius: 6px;
        color:#ffffff;
        font-size: 16px;
        text-align: center;
        margin:30px 0 30px 40px;
      }
    }
  }   
}
.container{
  min-height: 80vh;
}
</style>
