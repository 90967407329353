<template>
  <el-dialog
    title="修改密码"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="500px"
  >

    <el-form ref="passwoForm" :model="passwoForm" label-width="90px">
      <el-form-item label="原密码">
        <el-input type="password" v-model="passwoForm.oldPassword"></el-input>
      </el-form-item>
      <el-form-item label="新密码">
        <el-input type="password" v-model="passwoForm.newPassword"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码">
        <el-input type="password" v-model="passwoForm.newAgainPassword"></el-input>
      </el-form-item>
    </el-form>

    <!-- <div class="form">
      <div class="input-box">
        <input class="input" placeholder="请输入原密码" v-model="passwoForm.oldPassword" type="password">
      </div>
      <div class="input-box">
        <input class="input" placeholder="请输入新密码" v-model="passwoForm.newPassword" type="password">
        
      </div>
      <div class="input-box">
        <input class="input" placeholder="确认新密码" v-model="passwoForm.newAgainPassword" type="oldPassword">
        
      </div>
      
    </div> -->
    <span slot="footer" class="changePwd">
      <el-button type="primary" @click="changePassword" class="changeBtn">确 定</el-button>
      <el-button @click="toggleState()" class="cancelBtn">取 消</el-button>
      
    </span>
  </el-dialog>
</template>

<script>
import { changePassword } from '@/api/api'
const PASSWORDFORM = {
  oldPassword: '',
  newPassword: '',
  newAgainPassword: ''
}
export default {
  name: "ModifyPwd",
  data() {
    return {
      dialogVisible: false,
      passwoForm: {},
      changeRules: {
        oldPassword: [
          { required: true, message: '请填写原密码', trigger: 'change' }
        ],
      }
    }
  },
  methods:{

    resetForm() {
      this.passwoForm = { ...PASSWORDFORM }
      this.$nextTick(() => {
        this.$refs['passwoForm'].clearValidate()
      })
    },

    toggleState(isShow = false) {
      this.dialogVisible = isShow
      this.resetForm()
    },

    async changePassword(){
      let params = this.passwoForm
      let response = await changePassword(params)
      if(+response.returnCode !== 10001) {
        this.$message.warning(response.returnMsg)
        return false
      }
      this.$message.success(response.returnMsg)
      this.$store.dispatch('clearUserInfo')
      this.$nextTick(() => {
        this.$router.replace({path: '/course'})
      })
    }
    
  }
}
</script>
<style lang="less" scoped>
.form{
  margin-bottom: 25px;
}
.changePwd{
  display: block;
  text-align: center;
  
}
.changeBtn{
  width: 115px;
  background: #FF3F47;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  margin: 0px 0 10px 40px;
}
.cancelBtn{
  width: 115px;
  border-radius: 6px;
  font-size: 16px;
  text-align: center;
  margin: 0px 0 10px 40px;
}
</style>
