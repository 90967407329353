<!--基本信息-->
<template>
    <div>
       <div class="infoContain">
            <div class="title">
                <h3>基本信息</h3>
            </div>
            <div class="infoBox">
                <el-form :model="infoForm"  ref="infoForm" label-width="80px"  v-if="getUserInfo">
                    <el-form-item label="注册手机" prop="phone">
                        <span>{{infoForm.phone}}</span>
                        <span class="modifyPassword"  @click="changePassword">修改密码</span>
                    </el-form-item>
                    <el-form-item label="昵称" prop="name" v-if="isEdit">
                        <el-input v-model="infoForm.name"/>
                    </el-form-item>
                    <el-form-item label="昵称" prop="name" v-else>
                        <span>{{infoForm.name}}</span>
                    </el-form-item>
                    <el-form-item label="性别" prop="sex" v-if="isEdit">
                        <el-radio-group v-model="infoForm.sex">
                          <el-radio :label="0">男</el-radio>
                          <el-radio :label="1">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="性别" prop="sex" v-else>
                        <span>{{infoForm.sex | sexFilter}}</span>
                    </el-form-item>
                    <el-form-item label="头像" prop="headImg">
                        <!-- <img :src="infoForm.headImg"> -->
                        <!-- https://web-platfrom.jsbxgwy.com/jsplatfrom-web/api/upload/file -->
                        <!-- https://web-platfrom.bxgwy.com/platfrom-web/api/upload/file -->

                        <el-upload
                          v-if="isEdit"
                          class="avatar-uploader"
                          :action="uploadUrl"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeAvatarUpload"
                          :headers="headers"
                          :on-remove="handleRemove">
                          <img v-if="infoForm.headImg" :src="infoForm.headImg" class="avatar">
                          <img v-if="!infoForm.headImg"  src="@/assets/images/mine/headImg.png" class="avatar">
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <!-- <el-dialog :visible.sync="dialogVisible">
                          <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog> -->
                        <img v-if="!isEdit&&infoForm.headImg" :src="infoForm.headImg" class="avatar">
                        <img v-if="!isEdit&&!infoForm.headImg"  src="@/assets/images/mine/headImg.png" class="avatar">
                        
                    </el-form-item>
                    
                </el-form>
                <div class="footer">
                    <button v-if="isEdit" class="updateBtn" @click="updateInfo()">保存</button>
                    <button v-else class="updateBtn" @click="isEditInfo()">编辑</button>
                    
                </div>
            </div>
        </div>
        <ModifyPwd ref="ModifyPwd"></ModifyPwd>

    </div>
    
</template>
<script>
import ModifyPwd from '@/components/mine/ModifyPwd'
import { updateUserInfo } from '@/api/api'

export default {
    name:'info',
    data(){
      return {
        isEdit:false,
        infoForm:{},
        imageUrl:null,
        headers: {},
        uploadUrl:'',
      }
    },
    components: {
      ModifyPwd
    },
    mounted() {
      this.headers.token = this.$store.state.userInfo.token
      if(process.env.VUE_APP_AREA === 'js'){
        this.uploadUrl = 'https://web-platfrom.jsbxgwy.com/jsplatfrom-web/api/upload/file '

        //江苏-备份服务器
        //this.uploadUrl = 'https://web-platfrombackup.jsbxgwy.com/platfrom-web/api/upload/file'

      }else{
        this.uploadUrl = process.env.NODE_ENV === 'production' ? 'https://web-platfrom.bxgwy.com/platfrom-web/api/upload/file' : 'http://47.110.77.30:9900/platfrom-web/api/upload/file' 
        
        //浙江-备份服务器
        //this.uploadUrl = 'https://web-platfrombackup.bxgwy.com/platfrom-web/api/upload/file'
      }
    },
    computed: {
      getUserInfo() {
        if(this.$store.state) {
            this.infoForm = JSON.parse( JSON.stringify(this.$store.state.userInfo))
            return this.$store.state.userInfo || null
            
        } else {
            return null
        }
      }
    },
    methods: {
      changePassword() {
        this.$refs.ModifyPwd.toggleState(true)
      },
      isEditInfo(){
        this.isEdit = true
        
      },

      async updateInfo(){
        const params = {
          name:this.infoForm.name,
          sex:+this.infoForm.sex,
          headImg:this.infoForm.headImg
        }
        let response = await updateUserInfo(params)
        if(+response.returnCode !== 10001) {
            return false
        }
        this.$message.success(response.returnMsg)
        this.setUserInfo(this.infoForm)
        this.isEdit = false
      },

      setUserInfo(val = null) {
        this.$store.dispatch("setUserInfo", val)
      },

      handleAvatarSuccess(res, file){
        this.infoForm.headImg = res.data.src
      },
      beforeAvatarUpload(file) {
        if(file.size/1024/1024>5){
            this.$alert('图片不能大于5M', '异常提示', {
              confirmButtonText: '确定',
              type: 'warning'
            });
            return false
        }
      },
      handleRemove(file, fileList) {

      },

    },
    
    filters: {
      sexFilter(val) {
        if(val===0) {
            return '男'
        } else if(val===1) {
            return '女'
        }
      },
    }
    
    
}
</script>
<style lang="less">
.infoContain{
    width:925px;
    padding:0 30px;
    overflow: hidden;
    background:#fff;
    border-left:1px solid #E8E8E8;
    min-height: 375px;
    float:left;
    .title h3{
        font-size: 22px;
        color:#333333;
        text-align: left;
        font-weight: normal;
        line-height: 75px;
        height: 75px;
    }
    .infoBox{
        .modifyPassword{
            float: right;
            width:110px;
            height:38px;
            line-height: 38px;
            border:1px solid #FF3F47;
            border-radius: 6px;
            color:#FF3F47;
            font-size: 16px;
            text-align: center;
            cursor: pointer;
        }
        .footer{
            width: 100%;
            height:100px;
            .updateBtn{
                width:115px;
                height:40px;
                line-height: 40px;
                background: #FF3F47;
                border-radius: 6px;
                color:#ffffff;
                font-size: 16px;
                text-align: center;
                margin:30px 0 30px 40px;
            }
        }
    }
    
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 18px;
    color: #8c939d;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }
</style>
