<!--我的课程-->
<template>
  <div>
    <div class="courseContain">
      <div class="title">
        <h3>我的课程</h3>
      </div>
      <div class="course_list">
        <ul class="ul">
          <li class="li" v-for="(item, index) of courseGroupList" :key="index" @click="jumpDetail(item)">
            <div class="tag" v-if="item.hotLabel">
              <p class="tag-left"></p>
              <p class="tag-center">{{item.hotLabel}}</p>
              <p class="tag-right"></p>
            </div>
              <div class="title">{{item.courseGroupName}}</div>
              <p class="tip">{{item.subtitle}}</p>
              <p class="tip">
                <i class="el-icon-time"></i>
                {{item.classTime}}
              </p>
              <div class="teacher-list" >
                <div class="teacher-item" v-for="(teacher, index) of item.teachers" :key="index">
                  <img :src="teacher.headPortrait" :alt="teacher.teacherName">
                  <p class="name">{{teacher.teacherName}}</p>
                </div>
              </div>
              <div class="info-btn">
                <div class="ib-left">
                  <!-- <p class="ib-tip">{{teacher.costDescription}}</p> -->
                </div>
                <div class="ib-right">
                  <p class="isPay">已支付</p>
                </div>
              </div>
            </li>
        </ul>
        <div class="empty" v-if="courseGroupList.length === 0">
          <img src="@/assets/images/mine/empty.png" alt="暂无数据">
        </div>
      </div>
      <el-pagination
        class="page-box"
        v-if="courseGroupList.length !== 0"
        background
        layout="prev, pager, next"
        @current-change="pageChange"
        :page-size="limit"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {getOwnCourseGroup} from '@/api/api'
export default {
  name:'course',
  data() {
    return{
      page: 1,
      limit: 6,
      total: 0,
      courseGroupList: [], // 课程组列表
    }
  },
  mounted(){
    this.getCourseGroup()
  },
  methods: {
    pageChange(page) {
      this.page = page
      this.getCourseGroup();
    },
    jumpDetail(item) {
      this.$router.push({path: `/course/${item.id}`})
    },
    // 获取课程组
    async getCourseGroup() {
      const params = {
        page: this.page,
        limit: this.limit
      }
      let response = await getOwnCourseGroup(params)
      if(+response.returnCode !== 10001) {
        return false
      }
      this.courseGroupList = response.data
      this.total = response.total
    },
  }
}
</script>
<style lang="less" scoped>
.courseContain{
  width:985px;
  padding:0 30px;
  overflow: hidden;
  background:#fff;
  min-height: 375px;
  border-left:1px solid #E8E8E8;
  float:left;
  .title h3{
    font-size: 22px;
    color:#333333;
    text-align: left;
    font-weight: normal;
    line-height: 75px;
    height: 75px;
  }
  .course_list{
    .ul{
      margin:0;
      padding:0;
      overflow: hidden;
      .li{
        width: 230px;
        height: 220px;
        background: #FFFFFF;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.14);
        border-radius: 10px;
        position: relative;
        padding: 35px 25px 35px 25px;
        float: left;
        margin: 16px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover{
          transform: translateY(-5px);
        }
        .tag{
          position: absolute;
          min-width: 88px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          text-align: center;
          color: #fff;
          top:10px;
          left: 0;
          // background:;
          background-size: contain;
          user-select: none;
          display: flex;
          .tag-left{
            background: url('../../assets/images/courseGroup/tag-left.png') no-repeat top left;
            flex-grow: 0;
            flex-shrink: 0;
            width: 4px;
            height: 23px;
          }
          .tag-center{
            background: url('../../assets/images/courseGroup/tag-center.png') repeat top left;
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;
          }
          .tag-right{
            background: url('../../assets/images/courseGroup/tag-right.png') no-repeat top right;
            flex-grow: 0;
            flex-shrink: 0;
            width: 9px;
            height: 20px;
          }
        }
        .title{
          height: 52px;
          line-height: 26px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 8px;
          overflow : hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .tip{
          font-size: 12px;
          height: 12px;
          line-height: 12px;
          font-weight: 400;
          color: #5B6B73;
          margin-bottom: 15px;
          overflow: hidden;
          text-overflow:ellipsis; 
          white-space: nowrap;
        }
        .teacher-list{
          display: flex;
          height: 85px;
          border-bottom: 1px solid #e8e8e8;
          .teacher-item{
          width: 60px;
          margin-right: 10px;
          height: 60px;
          border-radius: 50%;
          // overflow: hidden;
          img{
              display: block;
              width: 44px;
              height: 44px;
              overflow: hidden;
              margin-bottom: 8px;
              margin-left: 8px;
              border-radius: 50%;
          }
          .name{
              width: 28px;
              height: 15px;
              line-height: 15px;
              font-size: 14px;
              font-weight: 400;
              color: #999999;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              text-align: center;
              width: 100%;
          }
          }
        }
        .info-btn{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 56px;
            .ib-left{
            .price{
              margin-bottom: 15px;
            }
            .price-new{
              height: 18px;
              line-height: 18px;
              font-size: 22px;
              font-weight: bold;
              color: #FF3F47;
              line-height: 26px;
            }
            .price-old{
              height: 12px;
              line-height: 12px;
              font-size: 14px;
              font-weight: 400;
              text-decoration: line-through;
              color: #5B6B73;
            }
            .ib-tip{
              height: 15px;
              line-height: 15px;
              font-size: 14px;
              font-weight: 400;
              color: #5B6B73;
            }
            }
            .ib-right{
              // width: 95px;
              height: 29px;
              line-height: 29px;
              text-align: center;
              background: #FFFFFF;
              color: #FF3F47;
              font-size: 16px;
              font-weight: bold;
            }
        }
      }
      .li:nth-child(3n){
        margin-right: 0px; 
      }
    }  
  }
}
.empty{
  height: 435px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 169px;
    height: 216px;
    display: block;
  }
}
</style>
