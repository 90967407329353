<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-03-26 18:00:32
 * @LastEditTime : 2021-04-07 14:25:11
 * @LastEditors  : XiaoLei
-->
<!--疑问与帮助-->
<template>
  <div>
    <div class="questionContain">
      <div class="title">
        <h3>疑问与帮助</h3>
      </div>
      <div class="question_list">
        <ul>
          <li v-for="(item, index) of list" :key="index">
            <i></i>
            <div class="question_right">
              <p>{{item.question}}</p>
              <span>{{item.answer}}</span>
            </div>
          </li>
        </ul>
      </div>
      <el-pagination
        hide-on-single-page
        :total="total"
        layout="prev, pager, next">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getqQuestionHelp } from '@/api/api'
export default {
  name:'question',
  data() {
    return {
      params: {
        limit: 10,
        page: 1,
      },
      list: [],
      total: 0,
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      getqQuestionHelp(this.params).then(res => {
        this.list = this.list.concat(res.data || []);
        this.total = res.total;
        this.params.page ++;
      })
    }
  },
}
</script>
<style lang="less">
.mineContainer{
  width:1200px;
  overflow: hidden;
  background:#fff;
  margin:0 auto 30px auto;
}
.questionContain{
  width:925px;
  padding:0 30px;
  overflow: hidden;
  background:#fff;
  border-left:1px solid #E8E8E8;
  min-height: 375px;
  float:left;
  .title h3{
    font-size: 22px;
    color:#333333;
    text-align: left;
    font-weight: normal;
    line-height: 75px;
    height: 75px;
  }
  .question_list{
    ul{
      margin:0;
      padding:0;
      li{
        width:100%;
        cursor: pointer;
        display: flex;
        i{
          display:inline-block;
          float: left;
          width: 20px;
          height: 20px;
          background: url(../../assets/images/icon/icon-13.png) center no-repeat;
          margin-top:30px;
          margin-right:18px;
          background-size: 100% 100%;
        }
        .question_right{
          width: 100%;
          overflow: hidden;
          padding:27px 0;
          border-bottom:1px solid #E8E8E8;
          p{
            font-size: 16px;
            color:#333333;
            text-align: left;
            margin-bottom:16px;
          }
          span{
            display: block;
            font-size: 14px;
            color:#999999;
          }
        }
        .question_right:last-child{
          border-bottom:none;
        }
      }
    } 
  }
}
.el-pagination{
  text-align: center;
}
</style>
