<!--课程协议-->
<template>
    <div>
        <div class="protocolContain">
            <div class="title">
                <h3>课程协议</h3>
            </div>
            <div class="protocol_list">
                <ul>
                    <li v-for="(item, index) in protocolList" :key="index" @click="protocolDetail(item)">
                        <i></i><p>{{item.protocolName}}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { getProtocolList} from '@/api/api'
export default {
    name:'protocol',
    data(){
        return{
            page: 1,
            limit: 1000,
            protocolList:[],
        }
    },
    mounted(){
        this.getProtocolList()
    },
    methods:{
      // 获取订单
      async getProtocolList() {
        const params = {
            page: this.page,
            limit: this.limit
        }
        let response = await getProtocolList(params)
        if(+response.returnCode !== 10001) {
            return false
        }
        this.protocolList = response.data
      },

      protocolDetail(item){
        this.$router.push({path: `/mine/protocolDetail?id=${item.id}`})
      }
    },
    
    
}
</script>
<style lang="less">
.protocolContain{
    width:925px;
    padding:0 30px;
    overflow: hidden;
    background:#fff;
    border-left:1px solid #E8E8E8;
    min-height: 375px;
    float:left;
    .title h3{
        font-size: 22px;
        color:#333333;
        text-align: left;
        font-weight: normal;
        line-height: 75px;
        height: 75px;
    }
    .protocol_list{
        ul{
            margin:0;
            padding:0;
            li{
                width:100%;
                height:44px;
                line-height: 44px;
                cursor: pointer;
               i{
                    display:inline-block;
                    float: left;
                    width: 5px;
                    height: 5px;
                    background: #FF3F47;
                    border-radius: 50%;
                    margin:19px 9px;

               } 
               p{
                   font-size: 16px;
                   color:#333333;
                   text-align: left;
               }
                
            }  
            li:hover{
                background: #F2F2F2;
            }
        } 
    }
    
    
    
}
</style>
